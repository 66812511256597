import DropPanel  from './components/DropPanel';
import MainMenu   from './components/MainMenu';
import mobileMenu from './helpers/mobileMenu';
import popup      from './helpers/popup';
import userModal  from './helpers/userModal';

// Init
function init() {
	const dropPanel      = new DropPanel();
	const mainMenu       = new MainMenu();
	const dropPanelClose = document.querySelectorAll('[data-dropdown="close"]');

	dropPanelClose.forEach((item) => {
		item.addEventListener('click', (e) => {
			dropPanel.closeDropPanel();
		});
	});

	mobileMenu();
	popup();
	userModal();
}

(function () {
	init();
}());
