export default function mobileMenu() {
	const parentMenu = $('.main-menu__item--parent > a');
	if (parentMenu.length === 0) return;
	const breakpoint = window.matchMedia('(min-width : 1081px)');

	const enableDropMenu = () => {
		parentMenu.on('click', (e) => {
			e.preventDefault();
			$(e.currentTarget).closest('li').toggleClass('active');
		});
	};

	const disabledDropMenu = () => {
		parentMenu.off('click');
	};

	const breakpointChecker = () => {
		if (breakpoint.matches === true) {
			disabledDropMenu();
		} else if (breakpoint.matches === false) {
			enableDropMenu();
		}
	};
	try {
		breakpoint.addEventListener('change', breakpointChecker);
	} catch (e) {
		// Safari <14
		breakpoint.addListener(breakpointChecker);
	}

	breakpointChecker();
}
