export default function popup() {
	jQuery('[data-lightbox="inline"]').on('click', function () {
		const link         = jQuery(this);
		const linkSrc      = link.attr('href') || link.data('target');
		const linkSrcClear = linkSrc.replace('#', '');

		jQuery(this).magnificPopup({
			items : {
				src  : linkSrc,
				type : 'inline',
			},
			midClick     : true,
			removalDelay : 500,
			mainClass    : 'modal-animation',
			showCloseBtn : false,
			tClose       : 'Закрыть (Esc)',
			tLoading     : 'Загрузка...',
			callbacks    : {
				beforeOpen() {
					jQuery('body').addClass(`body-${linkSrcClear}`);
				},
				open() {
					jQuery('.mfp-content').prepend(
						'<button title="Закрыть (Esc)" type="button" class="mfp-close">'
						+ '<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">'
						+ '  <path d="M21 1 1 20.8592M1 1.14084 21 21" stroke="currentColor" stroke-width="2"/>'
						+ '</svg>'
						+ '</button>',
					);
				},
				beforeClose() {
					jQuery('.mfp-close').remove();
				},
				close() {
					jQuery('body').removeClass(`body-${linkSrcClear}`);
				},
			},
		}).magnificPopup('open');
		return false;
	});

	jQuery('[data-lightbox="ajax"]').magnificPopup({
		type         : 'ajax',
		midClick     : true,
		removalDelay : 500,
		mainClass    : 'modal-animation',
		showCloseBtn : false,
		preloader    : false,
		tClose       : 'Закрыть (Esc)',
		tLoading     : 'Загрузка...',
		callbacks    : {
			open() {
				jQuery('.mfp-content').prepend(
					'<button title="Закрыть (Esc)" type="button" class="mfp-close">'
					+ '<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">'
					+ '  <path d="M21 1 1 20.8592M1 1.14084 21 21" stroke="currentColor" stroke-width="2"/>'
					+ '</svg>'
					+ '</button>',
				);
			},
			beforeClose() {
				jQuery('.mfp-close').remove();
			},
		},
	});

	jQuery('[data-lightbox="iframe"]').magnificPopup({
		type         : 'iframe',
		midClick     : true,
		removalDelay : 500,
		mainClass    : 'modal-animation',
		showCloseBtn : false,
		preloader    : false,
		tClose       : 'Закрыть (Esc)',
		tLoading     : 'Загрузка...',
		callbacks    : {
			open() {
				jQuery('.mfp-content').prepend(
					'<button title="Закрыть (Esc)" type="button" class="mfp-close">'
					+ '<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">'
					+ '  <path d="M21 1 1 20.8592M1 1.14084 21 21" stroke="currentColor" stroke-width="2"/>'
					+ '</svg>'
					+ '</button>',
				);
			},
			beforeClose() {
				jQuery('.mfp-close').remove();
			},
		},
	});

	jQuery('[data-lightbox="image"]').magnificPopup({
		type                : 'image',
		closeOnContentClick : false,
		closeBtnInside      : false,
		midClick            : true,
		showCloseBtn        : false,
		removalDelay        : 500,
		mainClass           : 'modal-animation',
		tClose              : 'Закрыть (Esc)',
		tLoading            : 'Загрузка...',
		image               : {
			verticalFit : true,
			tError      : '<a href="%url%">Изображение</a> не может быть загружено.',
		},
		gallery : {
			enabled : false,
		},
	});

	jQuery('[data-lightbox="gallery"]').magnificPopup({
		delegate            : 'a',
		type                : 'image',
		closeOnContentClick : false,
		closeBtnInside      : false,
		midClick            : true,
		showCloseBtn        : false,
		removalDelay        : 500,
		mainClass           : 'modal-animation',
		tClose              : 'Закрыть (Esc)',
		tLoading            : 'Загрузка...',
		image               : {
			verticalFit : true,
			tError      : '<a href="%url%">Изображение</a> не может быть загружено.',
		},
		gallery : {
			tPrev    : 'Назад (Стрелка влево)',
			tNext    : 'Вперед (Стрелка в право)',
			tCounter : '%curr% из %total%',
			enabled  : true,
		},
		callbacks : {
			open() {
				jQuery('.mfp-content').prepend(
					'<button title="Закрыть (Esc)" type="button" class="mfp-close">'
					+ '<svg fill="none" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">'
					+ '  <path d="M21 1 1 20.8592M1 1.14084 21 21" stroke="currentColor" stroke-width="2"/>'
					+ '</svg>'
					+ '</button>',
				);
			},
			beforeClose() {
				jQuery('.mfp-close').remove();
			},
		},
	});
}
