export default function userModal() {
	// eslint-disable-next-line no-undef
	const modal = jQuery('.user-modal');
	if (modal.length === 0) return;

	const trigger = modal.find('.user-modal__trigger');
	const panel   = modal.find('.user-modal__item');

	trigger.on(
		'click',
		(e) => {
			e.preventDefault();
			const self   = jQuery(e.target);
			const target = self.data('target');
			trigger.removeClass('active');
			panel.removeClass('active');

			self.addClass('active');
			jQuery(target).addClass('active');
		},
	);
}
